<template>
    <b-overlay :show="showLoader">
        <div class="text my-1 font-medium-2" style="color: black;">Izberite svojega upravnika:</div>
        <b-row>
            <b-col xs="12" lg="6" >
                <div class=" mt-1 d-flex align-content-center" style="cursor: pointer; background-color: #F4F4F4; border-radius: 20px; max-width: 270px; height: 65px;" @click="selectManagerHabit">
                    <b-row align-content="center" align-v="center" class="w-100">
                        <b-col cols="3">
                            <div class="check p-1 ml-1" style="margin-right: 5px;">
                                <label class="check">
                                    <input type="radio" ref="habit" name="select_manager"/>
                                    <span class="mark"></span>
                                </label>
                            </div>
                        </b-col>
                        <b-col cols="9">
                            <span class="text text-center w-100 d-flex justify-content-center">
                                <b-img class="" fluid :src="habitLogo" style="padding: 5px; padding-left: 15px!important;" />
                            </span>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
            <b-col xs="12" lg="6" >
                <div class="mt-1 d-flex align-content-center w-100" style="cursor: pointer; background-color: #F4F4F4; border-radius: 20px; max-width: 270px; height: 65px;" @click="selectManagerStanInvest">
                    <b-row align-content="center" align-v="center" class="w-100">
                        <b-col cols="3">
                            <div class="check p-1 ml-1" style="margin-right: 5px;">
                                <label class="check">
                                    <input type="radio" ref="stanInvest" name="select_manager"/>
                                    <span class="mark"></span>
                                </label>
                            </div>
                        </b-col>
                        <b-col cols="9">
                            <span class="text text-center w-100 d-flex justify-content-center">
                                <b-img class="" fluid :src="stanInvestLogo" style="padding: 5px; padding-left: 18px!important; max-height: 55px;" />
                            </span>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
            <b-col xs="12" lg="6" >
                <div class=" mt-1 d-flex align-content-center" style="cursor: pointer; background-color: #F4F4F4; border-radius: 20px; max-width: 270px; height: 65px;" @click="selectManagerOther">
                    <b-row align-content="center" align-v="center" class="w-100">
                        <b-col cols="3">
                            <div class="check p-1 ml-1" style="margin-right: 5px;">
                                <label class="check">
                                    <input type="radio" ref="other" name="select_manager"/>
                                    <span class="mark"></span>
                                </label>
                            </div>
                        </b-col>
                        <b-col cols="9">
                            <div class="text font-medium-4 text-center w-100" style="color: black;">
                                Drugo
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
        </b-row>

        <!-- form register -->
        <validation-observer v-if="selectedManeger==='habit' || selectedManeger=='stanInvest'" ref="registerValidation">
            <div class="text mt-3 mb-2 font-medium-2" style="color: black;">Izpolnite spodnji obrazec in se registrirajte:</div>
            <b-form class="auth-login-form mt-1">
                <b-form-group>
                    <validation-provider #default="{ errors }" name="ime" rules="required|min:3|max:30">
                        <b-form-input class="register" id="name" v-model="register.name" placeholder="Ime" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group>
                    <validation-provider #default="{ errors }" name="priimek" rules="required|min:3|max:30">
                        <b-form-input class="register" id="surname" v-model="register.surname" placeholder="Priimek" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group>
                    <validation-provider #default="{ errors }" name="e-naslov" rules="required|email">
                        <b-form-input class="register" id="email" v-model="register.email" placeholder="E-naslov" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group>
                    <span style="position: absolute; right: 10px; margin-top: 12px;">
                        <fa class="ml-1" id="supported-phone-formats-tooltip" icon="question-circle" color="#72a5d8" />
                        <b-tooltip target="supported-phone-formats-tooltip" placement="topright" triggers="hover focus" custom-class="expanded-tooltip">
                            Podprti formati:<br>
                            +386 51 123 456<br>
                            00386 51 123 456<br>
                            051 123 456
                        </b-tooltip>
                    </span>
                    <validation-provider #default="{ errors }" name="telefonska številka" rules="required|phone_number">
                        <b-form-input class="register" id="phone" v-model="register.telephone_number" placeholder="Telefonska številka" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group>
                    <span style="position: absolute; right: 10px; margin-top: 12px;">
                        <fa class="ml-1" id="code-payer-tooltip" icon="question-circle" color="#72a5d8" />
                        <b-tooltip target="code-payer-tooltip" placement="topright" triggers="hover focus" custom-class="expanded-tooltip">
                            Prepišite uporabniško ime, ki se nahaja na prvi strani računa (položnice)
                        </b-tooltip>
                    </span>
                    <validation-provider #default="{ errors }" name="šifra plačnika" rules="required">
                        <b-form-input class="register" v-model="register.code_payer" placeholder="Šifra plačnika" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group>
                    <span style="position: absolute; right: 10px; margin-top: 12px;">
                        <fa class="ml-1" id="tooltip3" icon="question-circle" color="#72a5d8" />
                        <b-tooltip target="tooltip3" placement="topright" triggers="hover focus" custom-class="expanded-tooltip">
                            Prepišite geslo, ki je zapisano na računu (položnici)
                        </b-tooltip>
                    </span>
                    <validation-provider #default="{ errors }" name="Varnostna koda" rules="required">
                        <b-form-input class="register" v-model="register.bill_password" type="password" placeholder="Geslo iz položnice"/>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group>
                    <b-form-input :disabled="true" class="register" v-model="register.manager.name" placeholder="Upravnik"/>
                </b-form-group>
                <b-form-group>
                    <span style="position: absolute; right: 10px; margin-top: 12px;">
                        <fa class="ml-1" id="tooltip2" icon="question-circle" color="#72a5d8" />
                        <b-tooltip target="tooltip2" placement="topright" triggers="hover focus" custom-class="expanded-tooltip">
                            <!-- <b>Geslo mora biti dolgo vsaj </b> -->
                            Geslo mora biti dolgo vsaj <b>8 znakov</b>. Vsebovati mora vsaj eno <b>veliko črko</b> in <b>številko</b>.
                        </b-tooltip>
                    </span>
                    <validation-provider #default="{ errors }" name="geslo" rules="required|min:8|at_least_one_uppercase|at_least_one_digit">
                        <b-form-input class="register" v-model="register.password" type="password" placeholder="Geslo"/>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group>
                    <validation-provider #default="{ errors }" name="ponovno geslo" rules="required|min:8|at_least_one_uppercase|at_least_one_digit|confirmed:geslo">
                        <b-form-input type="password" class="register" v-model="register.password_repeat" placeholder="Ponovno geslo" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group>
                    <validation-provider #default="{ errors }" name="pogoji" :rules="{ required: { allowFalse: false } }">
                        <b-row class="px-1">
                            <b-col cols="1" class="px-0 px-md-1">
                                <label class="check">
                                    <input type="checkbox" v-model="terms" name="terms"/>
                                    <span class="remember mark"></span>
                                </label>
                            </b-col>
                            <b-col cols="11" class="px-0 px-md-1 pl-1 pl-md-0" style="margin-top: 2px;">
                                <span class="text" style="font-size: 14px;">Strinjam se s
                                    <router-link class="text-secondary" :to="getTermsAndConditionsRoute" target="_blank">Pogoji poslovanja upravnika</router-link> in potrjujem seznanjenost z
                                    <router-link class="text-secondary" :to="getPrivacyPolicyRoute" target="_blank">Informacijami o obdelavi osebnih podatkov</router-link>.
                                </span>
                            </b-col>
                        </b-row>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>

                <!-- raffle -->
                <b-form-group v-if="raffle && raffle.active">
                    <validation-provider #default="{ errors }" name="pogoji nagradne igre">
                        <b-row class="px-1">
                            <b-col cols="1" class="px-0 px-md-1">
                                <label class="check">
                                    <input type="checkbox" v-model="openingTerms" name="openingTerms"/>
                                    <span class="remember mark"></span>
                                </label>
                            </b-col>
                            <b-col cols="11" class="px-0 px-md-1 pl-1 pl-md-0" style="margin-top: 2px;">
                                <span class="text" style="font-size: 14px;">Soglašam s pogoji nagradne igre
                                    <a class="text-secondary" @click="routeRaffleDescription()" >{{ raffle.title }}</a>
                                    in
                                    <a class="text-secondary"  @click="routeRaffleTerms()" >
                                        PRAVILI ZASEBNOSTI NAGRADNE IGRE
                                    </a>
                                </span>
                            </b-col>
                        </b-row>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>


                <!-- reCaptcha -->
                <b-form-group class="d-flex justify-content-center">
                    <vue-recaptcha ref="chapta_instance" @verify="verifyReCaptcha" :sitekey="siteKey" :loadRecaptchaScript="true"/>
                </b-form-group>

                <!-- submit buttons -->
                <b-form-group class="text-right mt-2">
                    <b-button :disabled="!reCaptcha" pill class="login-button" @click="validationFormRegister">REGISTRACIJA</b-button>
                </b-form-group>
            </b-form>
        </validation-observer>
        <validation-observer v-if="selectedManeger==='other'" ref="registerValidationOther">
            <div class="text mt-3 mb-2 font-medium-2" style="color: black;">Preverite ali je vaš trenutni upravnik primeren za uporabo<br> spletnega portala eSoseska, <span style="font-weight: bold;">preden se lahko registrirate*</span></div>
            <b-form class="auth-login-form mt-1">
                <b-form-group>
                    <validation-provider #default="{ errors }" name="ime" rules="required|min:3|max:30">
                        <b-form-input class="demand" v-model="demand.name" placeholder="Ime" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group>
                    <validation-provider #default="{ errors }" name="priimek" rules="required|min:3|max:30">
                        <b-form-input class="demand" v-model="demand.surname" placeholder="Priimek" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group>
                    <validation-provider #default="{ errors }" name="e-naslov" rules="required|email">
                        <b-form-input class="demand" v-model="demand.email" placeholder="E-naslov" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group>
                    <span style="position: absolute; right: 10px; margin-top: 12px;">
                        <fa class="ml-1" id="supported-phone-formats-tooltip" icon="question-circle" color="#72a5d8" />
                        <b-tooltip target="supported-phone-formats-tooltip" placement="topright" triggers="hover focus" custom-class="expanded-tooltip">
                            Podprti formati:<br>
                            +386 51 123 456<br>
                            00386 51 123 456<br>
                            051 123 456
                        </b-tooltip>
                    </span>
                    <validation-provider #default="{ errors }" name="telefonska številka" rules="required|phone_number">
                        <b-form-input class="demand" v-model="demand.telephone_number" placeholder="Telefonska številka" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group>
                    <validation-provider #default="{ errors }" name="naslov" rules="required">
                        <b-form-input class="demand" v-model="demand.address" placeholder="Naslov (ulica, kraj, poštna št.)" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group>
                    <validation-provider #default="{ errors }" name="regija" rules="required">
                        <b-form-input class="demand" v-model="demand.region" placeholder="Regija" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group>
                    <validation-provider #default="{ errors }" name="sporočilo" rules="required">
                        <b-form-textarea class="demand" v-model="demand.content" rows="8" placeholder="Sporočilo" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                <b-form-group class="text-right mt-2">
                    <b-button pill class="login-button" @click="registerValidationOther">POŠLJI POPRAVŠEVANJE</b-button>
                </b-form-group>
            </b-form>
        </validation-observer>
    </b-overlay>

</template>
<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, email, min, atLeastOneDigit, atLeastOneUppercase, PhoneNumber, confirmed } from '@validations'
    import VueRecaptcha from 'vue-recaptcha'
    import {BRow, BCol,  BFormGroup,  BForm, BButton, BFormInput, BTooltip, BOverlay, BImg, BFormTextarea} from 'bootstrap-vue'
    import nil from 'uuid/dist/nil'
    export default {
        components: {
            ValidationProvider,
            ValidationObserver,
            VueRecaptcha,
            BRow,
            BCol,
            BFormGroup,
            BForm,
            BButton,
            BFormInput,
            BTooltip,
            BOverlay,
            BImg,
            BFormTextarea
        },
        data() {
            return {
                terms: false,
                openingTerms: false,
                reCaptcha: false,
                recaptchaResponse: '',
                siteKey: this.$store.state.user.siteKey,
                register: {name: '', surname: '',  email: '', telephone_number: '', password: '', code_payer: '', administrator: false, manager: '', bill_password: ''},
                managers: this.$store.state.app.managers,
                required,
                email,
                min,
                atLeastOneDigit,
                atLeastOneUppercase,
                PhoneNumber,
                confirmed,
                selectedManeger:'',
                showLoader: false,
                demand: {name: '', surname: '',  email: '', telephone_number: '', address: '', region: '', content: ''},
                habitLogo: require('@/assets/images/icons/habit_logo.png'),
                stanInvestLogo: require('@/assets/images/icons/stan_invest_logo.png'),
                raffle:null
            }
        },
        methods: {
            registerValidationOther() {
                this.$refs.registerValidationOther.validate().then(success => {
                    if (success) {
                        this.send()
                    }
                })
            },
            validationFormRegister() {
                this.$refs.registerValidation.validate().then(success => {
                    if (success) {
                        this.registerUser()
                    }
                })
            },
            async loadRaffle() {
                try {
                    this.showLoader = true
                    const response = await this.$http.get('/api/user/v1/pages/awarding_page')
                    this.raffle = response.data ?? nil

                } catch {
                    this.$printError('Prišlo je do napake pri nalaganju podatkov!')
                } finally {
                    this.showLoader = false
                }
            },
            send() {
                const thisIns = this
                thisIns.showLoader = true

                const loadPromise = this.$http.post('/api/user/v1/send_mail/send_demand', this.demand)
                loadPromise.then(function() {
                    thisIns.$printSuccess('Povprašeavnje je bilo uspešno poslano')
                    thisIns.demand = {name: '', surname: '',  email: '', telephone_number: '', address: '', region: '', content: ''}
                    thisIns.showLoader = false
                    thisIns.$emit('on-register-successful')
                }).catch(function() {
                    thisIns.$printError('Prišlo je do napake pri pošiljanju podatkov, prosimo poskusite ponovno!')
                    thisIns.showLoader = false
                })
            },
            registerUser() {
                const thisIns = this
                thisIns.showLoader = true

                const payload = {
                    user: {...thisIns.register, manager: thisIns.register.manager.id},
                    recaptcha: thisIns.recaptchaResponse
                }

                const loadPromise = this.$http.put('/api/user/v1/register', payload)
                loadPromise.then(function() {
                    thisIns.$printSuccess('Registracija je bila uspešna')
                    thisIns.register = {name: '', surname: '',  email: '', telephone_number: '', password: '', code_payer: '', administrator: false, manager: '', bill_password: ''}
                    thisIns.showLoader = false
                    thisIns.$emit('on-register-successful')
                }).catch(function(error) {
                    if (error.response && error.response.status === 409) {
                        if (error.response && error.response.data === 'User exists') {
                            thisIns.$printError('Uporabnik z vpisanim e-naslovom že obstaja')
                        } else {
                            thisIns.$printError(error.response.data)
                        }
                    } else thisIns.$printError('Prišlo je do napake pri registraciji, prosimo poskusite ponovno!')
                    thisIns.showLoader = false
                    thisIns.$refs.chapta_instance.reset()                
                })
            },
            onEnterPressed(event) {
                const pressedKey = event.key || event.keyCode
                if (pressedKey === 'Enter' || pressedKey === 13) { 
                    this.validationFormRegister()
                }
            },
            verifyReCaptcha(response) {
                if (response) this.reCaptcha = true
                this.recaptchaResponse = response
            },
            selectManagerHabit() {
                this.selectedManeger = 'habit'
                this.$refs.habit.click()
                this.register.manager = this.managers.find((manager) => manager.id === 'habit')
            },
            selectManagerStanInvest() {
                this.selectedManeger = 'stanInvest'
                this.$refs.stanInvest.click()
                this.register.manager = this.managers.find((manager) => manager.id === 'staninvest')
            },
            selectManagerOther() {
                this.selectedManeger = 'other'
                this.$refs.other.click()
            },
            routeRaffleDescription() {
                if (this.raffle.external) {
                    window.open(this.raffle.link, '_blank')
                } else {
                    const routeData = this.$router.resolve({name: 'awarding'})
                    window.open(routeData.href, '_blank')
                }
            },
            routeRaffleTerms() {
                if (this.raffle.external_terms) {
                    window.open(this.raffle.link_terms, '_blank')
                } else {
                    const routeData = this.$router.resolve({name: 'awarding-terms'})
                    window.open(routeData.href, '_blank')
                }
            }
        },
        computed:{
            getTermsAndConditionsRoute() {
                if (this.selectedManeger) {
                    return {name: 'legal-notice', params: {upravnik: this.selectedManeger}}
                } else return ''
            },
            getPrivacyPolicyRoute() {
                if (this.selectedManeger) {
                    return {name: 'privacy-policy', params: {upravnik: this.selectedManeger}}
                } else return ''
            }
        },
        mounted() {
            this.loadRaffle()
            window.addEventListener('keyup', this.onEnterPressed, true)
        },
        beforeDestroy() {
            window.removeEventListener('keyup', this.onEnterPressed, true)
        }

    }
</script>
<style scoped>
    .login-button {
        background: #72A5D8 !important;
        font-weight: bold;
        border: none;
        color: white !important;
    }
    .login-button:hover {
        box-shadow: none !important;
    }
    .form input {
        background-color: transparent !important;
    }
    .text, .login-select input::placeholder {
        color: #626363;
    }
    .login::placeholder, .register::placeholder, .register-phone input::placeholder {
        color: #626363 !important;
    }
    .input-group-text {
        background-color: #009FD4;
        border: 1px solid #009FD4;
        color: white;
    }
    input:focus, .form-control:focus + .input-group-append .input-group-text {
      background-color: white !important;
      border-color: white !important;
      box-shadow: 0 3px 10px 0 rgba(0,0,0, 0.1) !important;
    }
</style>
<style>
    .tooltip .arrow{
        display: none !important;
    }
    .register-form .tooltip {
        position: absolute !important;
        right: 10px !important;
        margin-top: 12px !important;
    }
    .select-manager .v-select .vs__dropdown-toggle {
      background-color: #F4F4F4 !important;
    }
    .select-manager .v-select .vs__dropdown-toggle {
      height: 49px;
    }
    .select-manager .v-select .vs__selected, .select-manager .v-select input {
      padding-left:10px !important;
    }
    .select-manager .v-select .vs__dropdown-toggle .vs__actions > span {
      width: 50px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
</style>
